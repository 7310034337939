import { useState } from "react";
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import ReactGA from 'react-ga4'

export default function About() {

  return (
<>
  {/* <div className="About"> */}
<Grid container spacing={5}>
  <Grid item sm={12} md={12} lg={12} >
  <Card>
    <CardMedia
      sx={{ height: 140 }}
      image="static/images/cards/about.jpg"
      title="about"
    />
    <CardContent>
      <Typography gutterBottom variant="h5" component="div">
        About
      </Typography>
      <Typography variant="body2" color="text.secondary">
        This website is developed and maintained by Shibin Varghese
      </Typography>
    </CardContent>
  </Card>
  </Grid>
</Grid>
  {/* </div> */}
  </>
  );

}
