import Navbar from "./Navbar"
import Home from "./pages/Home"
import About from "./pages/About"
import SaveLoan from "./pages/SaveLoan"
import { Route, Routes } from "react-router-dom"
import ReactGA from 'react-ga4'
import { useEffect } from "react";


function App() {
  // Google Analytics - initialize
  ReactGA.initialize('G-MNZFJ3WG0Q');
  // Google Analytics - page view
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  return (
    <>
      <Navbar />
      <div className="container">
        <Routes>
          <Route path="/" element={<SaveLoan />} />
          <Route path="/about" element={<About />} />
          <Route path="/saveloan" element={<SaveLoan />} />
        </Routes>
      </div>
    </>
  )
}

export default App
